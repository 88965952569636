import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import Layout from '../components/Layout'
import SocialSideBar from '../components/SocialSideBar'
import styles from './contact.module.scss'

const Contact = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [state, setState] = useState({})

  const onOpenModal = () => {
    setModalIsOpen(true)
  }

  const onCloseModal = () => {
    setModalIsOpen(false)
  }

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(onOpenModal())
      .catch(error => alert(error))
  }

  // helper to url encode form data
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  return (
    <Layout>
      <Modal open={modalIsOpen} onClose={onCloseModal}>
        <div className={styles.modalBody}> éxito</div>
      </Modal>
      <section className={styles.sectionOne}>
        <SocialSideBar />
        <div className={styles.imageContainer}>
          <div className={styles.textContainer}>
            <h2>¡Contáctanos! Nos encantaría saber de ti</h2>
            <h4>
              Tenemos un equipo de servicio al cliente dedicado a atender tus
              dudas y asegurarte una experiencia única.
            </h4>
          </div>
        </div>
      </section>
      <section className={styles.sectionTwo}>
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className={styles.row}>
            <input
              type="text"
              name="name"
              placeholder="Nombre *"
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Celular *"
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.row}>
            <input
              type="email"
              name="email"
              placeholder="Email *"
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="city"
              placeholder="Ciudad *"
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.row}>
            <textarea
              name="comments"
              placeholder="Comentarios o Preguntas *"
              cols="20"
              rows="5"
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.submitArea}>
            <div className={styles.checkboxContainer}>
              <input
                type="checkbox"
                id="accept"
                name="accept"
                value="accept"
                required
              />
              <label htmlFor="accept"> Acepto Términos y Condiciones</label>
            </div>
            <div className={styles.buttonContainer}>
              <input
                className="gradient-button-2"
                type="submit"
                value="Enviar mensaje"
              />
            </div>
          </div>
        </form>
      </section>
    </Layout>
  )
}

export default Contact
